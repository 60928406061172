import { Box, Button, Container, Flex, Progress, SimpleGrid, Stack, Table, Icon, Tbody, Td, Text, Th, Thead, Tr, TableContainer } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FaArrowCircleLeft, FaPrint } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getOrder } from "../../Helpers/Queries";
import WholesalerAddress from "../../Helpers/WholesalerAddress";
import WholesalerLogo from "../../Helpers/WholesalerLogo";
import NotFoundOrders from "../NotFound/NotFoundOrders";

function formatDate(date) {
  var d = new Date(date);
  d = d.toLocaleDateString("en-AU", { day: "2-digit", month: "2-digit", year: "2-digit" });
  return d;
}

const ViewInvoice = (props) => {
  let params = useParams();
  const { orderUID } = params;
  const { isLoading, isError, data } = useQuery({
    queryKey: ["getOrder", orderUID],
    queryFn: () => getOrder(orderUID),
  });

  if (isLoading) {
    return (
      <Container backgroundColor={"white"} maxW="container.xl" bg="white" py={5} minH={"100%"} color="black">
        <Progress size="lg" colorScheme={"blue"} isIndeterminate />
      </Container>
    );
  }
  if (isError || !data || data.data.error || data.data.orderDetails) {
    return <NotFoundOrders />;
  }

  let orderHeader = data.data.orderheader[0];
  let orderDetails = data.data.orderdetails;
  let orderAddress = data.data.orderaddress[0];
  let companyLogo;
  let companyDetails;
  let gsttotal = 0;

  let orderid = "#INV" + orderHeader.id.padStart(8, "0");
  let notes = orderHeader.notes ? (
    <Text>
      <span>
        <strong>Order Notes:&nbsp;</strong>
      </span>
      {orderHeader.notes}
    </Text>
  ) : (
    ""
  );

  let customerunit = orderAddress.street_unit ? orderAddress.street_unit + "/" : "";
  let customeraddress1 = customerunit + orderAddress.street_number + " " + orderAddress.street_name;
  let customeraddress2 = orderAddress.suburb + ", " + orderAddress.state + ", " + orderAddress.postcode;

  if (orderHeader.wholesaler_delivery === "DROPSHIP" && orderHeader.delivery === "1") {
    companyLogo = (
      <Box>
        <WholesalerLogo wholesaler={orderHeader.ordercompanyUID} />
      </Box>
    );
    companyDetails = <WholesalerAddress wholesaler={orderHeader.ordercompanyUID} showName showABN />;
  } else {
    companyLogo = (
      <Box>
        <WholesalerLogo wholesaler={orderHeader.companyUID} />
      </Box>
    );
    companyDetails = <WholesalerAddress wholesaler={orderHeader.companyUID} showName showABN />;
  }

  let products = (
    <TableContainer>
      <Table size={"sm"} fontSize={"12px"} width={"100%"} variant={"simple"}>
        <Thead>
          <Tr>
            <Th textAlign="left">Order Code</Th>
            <Th textAlign="left">Product</Th>
            <Th textAlign="center">Quantity</Th>
            <Th textAlign="right">Price</Th>
            <Th textAlign="right">Total</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderDetails.map((val, idx) => {
            let ordernumbers;
            let sell = val.sellinc;
            let gst = 0;
            if (val.gst === "1") {
              gst = parseFloat(parseFloat(sell) / 11).toFixed(2);
              sell = parseFloat(parseFloat(sell) / 1.1).toFixed(2);
            }
            let total;
            if (val.weighted === "0") {
              ordernumbers =
                val.orderqty !== val.pickedqty ? (
                  <span>
                    <span className="text-black-20">{val.pickedqty} |</span> {val.orderqty}
                  </span>
                ) : (
                  val.orderqty
                );
              total = (sell * val.orderqty).toFixed(2);
              gsttotal = parseFloat(parseFloat(gsttotal) + parseFloat(gst * val.orderqty)).toFixed(2);
            } else {
              if (parseFloat(val.actualweight).toFixed(2) !== parseFloat(parseFloat(val.orderqty) * parseFloat(val.cartonqty)).toFixed(2) && val.actualweight !== null) {
                ordernumbers = (
                  <span>
                    <span className="text-black-20">
                      {parseFloat(parseFloat(val.orderqty) * parseFloat(val.cartonqty)).toFixed(2)}kg ({val.orderqty}) |
                    </span>{" "}
                    {parseFloat(val.actualweight).toFixed(2)}kg
                  </span>
                );
              } else {
                ordernumbers = (
                  <span>
                    {parseFloat(parseFloat(val.orderqty) * parseFloat(val.cartonqty)).toFixed(2)}kg ({val.orderqty})
                  </span>
                );
              }
              sell = sell.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "kg";
              total = (sell * val.actualweight).toFixed(2);
              gsttotal = parseFloat(parseFloat(gsttotal) + parseFloat(gst * val.actualweight).toFixed(2)).toFixed(2);
            }

            return (
              <Tr key={idx}>
                <Td fontSize={"12px"}>{val.ordercode}</Td>
                <Td fontSize={"12px"}>
                  <Text textTransform={"uppercase"}>
                    {val.gst === "1" && <span>*</span>}
                    {val.name}
                  </Text>
                </Td>
                <Td textAlign="center" fontSize={"12px"}>
                  {ordernumbers}
                </Td>
                <Td textAlign="right" fontSize={"12px"}>
                  ${sell}
                </Td>
                <Td textAlign="right" fontSize={"12px"}>
                  ${parseFloat(total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </Td>
              </Tr>
            );
          })}
          <Tr>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              <strong>Subtotal</strong>
            </Td>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              ${parseFloat(parseFloat(orderHeader.ordertotal) - parseFloat(orderHeader.freight) - parseFloat(orderHeader.surcharge) - parseFloat(gsttotal)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
            </Td>
          </Tr>
          <Tr>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              <strong>GST</strong>
            </Td>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              ${parseFloat(parseFloat(gsttotal) - parseFloat(orderHeader.freight) - parseFloat(orderHeader.surcharge)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
            </Td>
          </Tr>
          <Tr>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              <strong>Freight Charge</strong>
            </Td>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              ${parseFloat(orderHeader.freight).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
            </Td>
          </Tr>
          <Tr>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              <strong>Payment Surcharge</strong>
            </Td>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              ${parseFloat(orderHeader.surcharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
            </Td>
          </Tr>
          <Tr>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              <strong>Order Total</strong>
            </Td>
            <Td colSpan="4" fontSize={"12px"} textAlign="right">
              <strong>${parseFloat(parseFloat(orderHeader.ordertotal) + parseFloat(orderHeader.freight) + parseFloat(orderHeader.surcharge)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth={"7xl"} backgroundColor={"white"} py={6} my={6} sx={{ "@media print": { padding: "0", margin: "0" } }}>
      <Flex sx={{ "@media print": { display: "none" } }} width={"100%"} flexDirection={"column"} align={"flex-end"}>
        <Box>
          <Button mx="1" leftIcon={<Icon as={FaPrint} />} onClick={() => window.print()} variant="solid" colorScheme={"red"}>
            PRINT INVOICE
          </Button>
          <Button mx="1" leftIcon={<Icon as={FaArrowCircleLeft} />} onClick={() => window.history.back()} variant="solid" colorScheme={"blue"}>
            GO BACK
          </Button>
        </Box>
      </Flex>
      <Stack direction={["column", "row"]} spacing={1}>
        <SimpleGrid
          columns={{
            base: 2,
            md: 2,
          }}
          w={{
            base: "100%",
            md: "full",
          }}
          textTransform="uppercase"
          py={{
            base: 1,
            md: 4,
          }}
          fontSize="md"
        >
          <Box>{companyLogo}</Box>
          <Box alignContent={"flex-end"} textAlign={"right"}>
            <Text fontSize={"2xl"} pb={3} fontWeight={"bold"}>
              ORDER SUMMARY
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>
              {orderid}
            </Text>
            <Text fontSize={"sm"}>
              <b>ORDER DATE:</b> {formatDate(orderHeader.order_created)}
            </Text>
            <Text fontSize={"sm"}>
              <b>PAYMENT METHOD:</b> {orderHeader.payment_method}
            </Text>
          </Box>
        </SimpleGrid>
      </Stack>
      <Stack direction={["column", "row"]} spacing={1}>
        <SimpleGrid
          columns={{
            base: 2,
            md: 2,
          }}
          w={{
            base: "100%",
            md: "full",
          }}
          textTransform="uppercase"
          py={{
            base: 1,
            md: 4,
          }}
          fontSize="md"
        >
          <Box>{companyDetails}</Box>
          <Box alignContent={"flex-end"} textAlign={"right"}>
            <Text fontSize={"13px"}>
              <span className="order-total">
                <strong>Bill To</strong>
              </span>
              <br />
              {orderAddress.company_name}
              <br />
              {orderAddress.customer}
              <br />
              {customeraddress1}
              <br />
              {customeraddress2}
            </Text>
          </Box>
        </SimpleGrid>
      </Stack>

      {notes}
      <Stack mt={10} width={"100%"}>
        {products}
        <small>* Indicates taxable item.</small>
      </Stack>
    </Container>
  );
};

export default ViewInvoice;
