import { VStack } from "@chakra-ui/react";
import FeaturedProducts from "../Callouts/FeaturedProducts";

const HomeLayout = (props) => {
  return (
    <VStack minH={"calc(100% - 183px)"} spacing={2} py={5}>
      {/* <Image src="/images/2024-closure.png" alt="2024 Closure" width="100%" maxW={"3xl"} height="auto" /> */}
      <FeaturedProducts />
    </VStack>
  );
};

export default HomeLayout;
