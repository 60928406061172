import { ButtonGroup, Flex, Button, SimpleGrid, Text, TagLeftIcon, Tag, TagLabel, Icon } from "@chakra-ui/react";
import { FaArrowCircleRight, FaFileInvoice, FaTasks } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../Cart/PriceTag";

function formatDate(date) {
  date = date.replace(/ /g, "T");
  var d = new Date(date);
  d = d.toLocaleTimeString("en-AU", { day: "2-digit", month: "2-digit", year: "2-digit" });
  return d;
}

function getStatus(status) {
  switch (status) {
    case "1":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Waiting to Pick</TagLabel>
        </Tag>
      );
    case "2":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Pending Payment</TagLabel>
        </Tag>
      );
    case "3":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Picked, Awaiting Dispatch</TagLabel>
        </Tag>
      );
    case "4":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Dispatched</TagLabel>
        </Tag>
      );
    case "5":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Delivered</TagLabel>
        </Tag>
      );
    case "6":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Picked, On Hold</TagLabel>
        </Tag>
      );
    case "99":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Order Cancelled</TagLabel>
        </Tag>
      );
    case "100":
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Order Failed</TagLabel>
        </Tag>
      );
    default:
      return (
        <Tag size={"md"} variant={"solid"}>
          <TagLeftIcon as={FaTasks} mr={1} />
          <TagLabel>Unknown</TagLabel>
        </Tag>
      );
  }
}

const OrderRow = (props) => {
  const navigate = useNavigate();
  const { order } = props;
  return (
    <>
      <Flex
        direction={{
          base: "row",
          md: "column",
        }}
        key={order.id}
      >
        <SimpleGrid
          spacingY={3}
          columns={{
            base: 1,
            md: 4,
          }}
          w={{
            base: "100%",
            md: "full",
          }}
          textTransform="uppercase"
          py={{
            base: 1,
            md: 4,
          }}
          px={{
            base: 0,
            md: 10,
          }}
          fontSize="md"
        >
          <Text fontWeight="bold">
            #INV{order.id.padStart(8, "0")}
            <br />
            <small>{formatDate(order.order_created)}</small>
          </Text>
          <Text align={{ base: "left", md: "center" }} fontWeight="bold">
            {getStatus(order.status)}
          </Text>
          <Text align={{ base: "left", md: "center" }} fontWeight="bold">
            <Tag size={"lg"} variant={"solid"} colorScheme={"green"}>
              {formatPrice(order.ordertotal)}
            </Tag>
          </Text>
          <ButtonGroup justifyContent={{ base: "center", md: "end" }} variant="solid" size="sm" spacing={3}>
            <Button colorScheme="blue" rightIcon={<Icon as={FaArrowCircleRight} />} aria-label="View Order" onClick={() => navigate("/vieworder/" + order.UID)}>
              View Order
            </Button>
            {(order.status === "4" || order.status === "5" || order.status === "6") && (
              <Button colorScheme="green" rightIcon={<Icon as={FaFileInvoice} />} aria-label="Order Summary" onClick={() => navigate("/viewinvoice/" + order.UID)}>
                Order Summary
              </Button>
            )}
          </ButtonGroup>
        </SimpleGrid>
      </Flex>
      <hr />
    </>
  );
};

export default OrderRow;
